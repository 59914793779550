import React from "react"
import { graphql } from "gatsby"

const Homepage = ({ data: { prismicHomepage } }) => {
  const { data } = prismicHomepage
  return (
    <React.Fragment>
      <h1>{data.title.text}</h1>
      <div dangerouslySetInnerHTML={{ __html: data.intro.html }} />
    </React.Fragment>
  )
}

export default Homepage

export const pageQuery = graphql`
  query HomepageById($id: String!) {
    prismicHomepage(id: { eq: $id }) {
      data {
        title {
          text
        }
        intro {
          html
        }
      }
    }
  }
`